<template lang="pug">
.page(:class='{isMobile:isMobile}')
  el-card
    el-form(label-width="6em" :label-position="isMobile?'top':'right'")
      el-form-item(label="商场名称")
        el-input(v-model.trim="form.commercialPlazaName" placeholder="请输入商场名称")
      el-form-item(label="商场LOGO")
        comUpload(:limit="1" :value="imgForm.logoUrl" @change="changeLogo")
        //- img(:src="form.logoUrl" class="logoUrl")
        //- div(style="width:100%")
        //-   el-button(type="success" size="small" @click="handlePickImage" v-if="!isMiniprogram") 选择商场LOGO
      el-form-item(label="商场环境图")
        comUpload(:limit="9" :value="imgForm.environmentImages" @change="changeEnvironmentImages")
        //- imgList
        //-   img(:src="url" class="logoUrl" v-for="url in form.environmentImages" :key="url" )
        //- div(style="width:100%")
        //-   el-button(type="success" size="small" @click="handlePickEnvironmentImages" v-if="!isMiniprogram") 选择商场环境图
      el-form-item(label="联系电话")
        div(class="phoneList")
          div(class="row" v-for="(item, index) in form.contactNumbers" :key="index")
            el-input(style="margin: 8px 0" placeholder="请输入电话" oninput="value=value.replace(/[^0-9-+]/g,'')" v-model="form.contactNumbers[index]")
            el-button(type="danger" style="margin-left: 6px" v-if="index !== 0" @click="deletePhone(index)" :icon="Delete" plain circle)
          div(class="row")
            el-button(type="success" plain size="small" v-show="form.contactNumbers.length < 3" @click="form.contactNumbers.push('')") 添加电话号码
      el-form-item(label="地址")
        el-input(placeholder="请填写门店地址" v-model.trim="form.address")
      el-form-item(label="地区")
        el-select(@change="getDistrictList" v-model="form.areaId" placeholder="请选择城市" disabled)
          el-option(v-for="item in areaList" :key="item.id" :label="item.areaName" :value="item.id")
      el-form-item(label="关联商圈")
        el-cascader(:options="districtList" v-model="form.businessDistrictIds" :props="{ multiple: true,value: 'id',label: 'districtTitle',children: 'child'}" clearable)
      el-form-item(label="关联标签")
        el-tag(v-for="i in form.labelDetails" closable style="margin:10px" @close="deleteLabel(i.labelId)") {{i.labelValue}}
        el-button(v-if="!isShowAddTag" type="success" size="small" @click="isShowAddTag=true") 添加标签
        template(v-else)
          el-select(v-model="newTagId")
            el-option(v-for="i in tagListFilterPicked" :label="i.tagName" :value="i.id")
          el-button(type="success" size="small" @click="handleSubmitAddTag") 确定添加
      el-form-item(label="商场详情")
        .switch(style="width:100%") 
          el-switch(active-text="显示" inactive-text="隐藏"  :active-value="1" :inactive-value="0" v-model="form.switchDetail")
        comEditor(v-model="form.detailContent")
      el-form-item()
        el-button(type="primary" style="width: 100%;" @click="handleSubmit" :loading="loading") 保存
  el-card
    .record(@click="router.push('/record/list')") 修改记录

</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'
import { Delete } from '@element-plus/icons-vue'
import comUpload from '@/component/common/upload'
import comEditor from '@/component/editor/index.vue'
import { currentMarketId } from '@/provider/account'
import { isMobile } from '@/provider/sys'
import { getCache, updateCache } from '@/provider/cache'
import { pickImageList } from '@/provider/upload'
import { isMiniprogram } from '@/provider/sys'

const { fetch, message, router } = inject('global')

const form = ref({
  id: '',
  commercialPlazaName: '',
  logoUrl: '',
  environmentImages: [],
  contactNumbers: [],
  address: '',
  areaId: '',
  labelDetails: [],
  lon: 0,
  lat: 0,
  businessDistrictIds: [],
  detailContent: '',
  switchDetail: 1
})

const imgForm = ref({
  logoUrl: '',
  environmentImages: []
})

const loading = ref(false)
const validateErrorMessage = computed(() => {
  if (!form.value.commercialPlazaName) return '名称不能为空'
  if (form.value.commercialPlazaName.length < 2) return '名称不能少于两个字'
  if (!form.value.logoUrl) return '商场LOGO不能为空'
  return ''
})

function handleSubmit() {
  if (validateErrorMessage.value) {
    message.error(validateErrorMessage.value)
  } else {
    loading.value = true
    fetch
      .post('/boom-center-product-service/businessAdmin/commercialPlazaAssistant/basicEdit', {
        ...form.value,
        businessDistrictIds: form.value.businessDistrictIds.map(item => {
          return item[item.length - 1]
        }),
        commercialPlazaId: form.value.id
      })
      .then(res => {
        loading.value = false
        message.success('保存成功，等待审核')
        router.go(-1)
      })
      .catch(err => {
        loading.value = false
        message.error(err?.msg || err)
      })
  }
}

function handlePickImage() {
  pickImageList({ count: 1 }).then(res => {
    if (res[0]) {
      form.value.logoUrl = res[0]
    }
  })
}

function handlePickEnvironmentImages() {
  pickImageList({ count: 5 })
    .then(res => {
      form.value.environmentImages = res
    })
    .catch(err => {
      message.error(err.msg || msg)
    })
}

const areaList = ref([])
function getAreaList() {
  fetch.get(`/boom-center-admin-service/api/areas`).then(res => {
    areaList.value = res
  })
}
const districtList = ref([])
// 关联商圈
function getDistrictList() {
  fetch
    .get(`/boom-center-product-service/sysAdmin/businessDistrict/all`, {
      headers: {
        ['Bm-Area-Id']: form.value.areaId
      }
    })
    .then(res => {
      districtList.value = res
      console.log(res)
    })
}
const tagList = ref([])
function getTagList() {
  fetch.get(`/boom-center-product-service/sysAdmin/storeTag/list?size=1000`).then(res => {
    tagList.value = res.list
  })
}
const isShowAddTag = ref(false)
const tagListFilterPicked = computed(() => {
  return tagList.value?.filter(i => {
    return !form.value?.labelDetails.find(j => j.labelId === i.id)
  })
})
const newTagId = ref('')
function handleSubmitAddTag() {
  if (!newTagId.value) {
    message.warning('标签名不能为空')
    isShowAddTag.value = false
    return
  } else {
    const newTagItem = tagListFilterPicked.value.find(i => i.id === newTagId.value)
    if (newTagItem) {
      form.value.labelDetails.push({
        labelId: newTagItem.id,
        labelValue: newTagItem.tagName
      })
    }
  }
}
function deleteLabel(id) {
  form.value.labelDetails = form.value.labelDetails.filter(v => v.labelId != id)
}

function deletePhone(index) {
  form.value.contactNumbers.splice(index, 1)
}

// 改变logo
function changeLogo(val) {
  form.value.logoUrl = val || ''
}

// 改变环境图列表
function changeEnvironmentImages(val) {
  form.value.environmentImages = val || []
}

function getConfig() {
  getAreaList()
  getTagList()
}
function getData() {
  return fetch.get(`/boom-center-product-service/sysAdmin/commercialPlaza/${currentMarketId.value}`).then(res => {
    for (let key in form.value) {
      form.value[key] = res[key]
    }
    for (let key in imgForm.value) {
      imgForm.value[key] = res[key]
    }
    // form.value = res
    form.value.businessDistrictIds = res.businessDistrictVOs.map(b => {
      if (b.parent) return [b.parent.id, b.id]
      return [b.id]
    })
    getDistrictList()
    return
  })
}

onMounted(async () => {
  getConfig()
  getDistrictList()
  await getData()
})
</script>

<style lang="less" scoped>
.phoneList {
  .row {
    display: flex;
    align-items: center;
  }
}
.logoUrl {
  width: 100px;
  height: 100px;
  margin: 0 5px 5px 0;
}

.record {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
.isMobile {
  padding: 0;
  .el-card {
    margin: 0;
    margin-bottom: 10px;
  }
  .editorContainer {
    min-width: 300px;
  }
}
</style>
